@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities"; */


/* :root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
} */

/* Probably not needed the min-width part */
 body {
  min-width: 340px; 
} 

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}


.better-to-buy:before {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 115%;
  top: 0;
  -webkit-transform: skewY(-12deg);
  transform: skewY(-12deg);
  background: #f6f9fc;
}

/* Ensure this CSS is included in your global styles or a CSS module */

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3); /* Semi-transparent black background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Make sure it is on top of all other content */
  visibility: hidden; /* Hide by default */
  opacity: 0; /* Hide by default */
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.spinner-overlay.active {
  visibility: visible; /* Show when active */
  opacity: 1; /* Show when active */
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  width: 4rem; /* Adjust to the size you need */
  height: 4rem; /* Adjust to the size you need */
}

.scale-down {
  transform: scale(0.8); /* 80% of the original size */
  transform-origin: center center; /* Optional: Adjust the transformation origin */
}

.text-border {
  -webkit-text-stroke: 2px #9ACD32; /* Adjust width and color here */
  -webkit-text-stroke: 2px #9ACD32;
  text-stroke: 2px #9ACD32; /* Some browsers may use this */
}

.text-highlight {
  position: relative;
  display: inline-block;
  padding: 0 0.5rem; /* Adjust padding to create space around the text */
}

.text-highlight::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 80%;
  height: 50%; /* Adjust height as needed */
  background: linear-gradient(120deg, #a7f30f 0%, #9ACD32 100%);
  background-size: 100% 80%;
  background-position: 0 0; /* Adjust position to match text alignment */
  background-repeat: no-repeat;
  z-index: -1;
  transform: translateY(-0%); /* Center the gradient vertically */
}

.text-highlight-violet {
  position: relative;
  display: inline-block;
  padding: 0 0.5rem; /* Adjust padding to create space around the text */
}

.text-highlight-violet::before {
  content: '';
  position: absolute;
  top: 95%;
  left: 0;
  width: 80%;
  height: 5%; /* Adjust height as needed */
  background: linear-gradient(120deg, #69529c 0%, #432680 100%);
  background-size: 100% 80%;
  background-position: 0 0; /* Adjust position to match text alignment */
  background-repeat: no-repeat;
  z-index: -1;
  transform: translateY(-0%); /* Center the gradient vertically */
}

/* Benutzerdefinierte Markdown-Stile */
.prose {
  max-width: 100%;
  margin: 0 auto;
  line-height: 1.6;
  font-size: 1rem;
}
/* Stil für Überschriften */
.prose h1,
.prose h2,
.prose h3,
.prose h4,
.prose h5,
.prose h6 {
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  line-height: 1;
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
  color: #43277E; /* Dunkler Text für bessere Lesbarkeit */
}

/* Stil für h1 (größte Überschrift) */
.prose h1 {
  font-size: 2rem; /* Größere Schrift für h1 */
  border-bottom: 2px solid #e2e8f0; /* Dünne Linie unter der h1 */
  padding-bottom: 0.5rem;
}

/* Stil für h2 */
.prose h2 {
  font-size: 1.5rem; /* Mittlere Größe für h2 */
  border-bottom: 1px solid #e2e8f0; /* Dünne Linie unter der h2 */
  padding-bottom: 0.5rem;
}

/* Stil für h3 */
.prose h3 {
  font-size: 1.2rem; /* Kleinere Schrift für h3 */
  color: #43277E; /* Etwas hellerer Text */
}

/* Stil für h4 */
.prose h4 {
  font-size: 1.25rem;
  color: #43277E;
}

/* Stil für h5 */
.prose h5 {
  font-size: 1.125rem;
  color: #43277E;
}

/* Stil für h6 */
.prose h6 {
  font-size: 1rem;
  color: #43277E;
  font-weight: normal; /* H6 bekommt weniger Gewicht */
}

.prose h2,
.prose h3,
.prose h4 {
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
}

.prose p {
  margin-bottom: 1.25rem;
}

.prose ul,
.prose ol {
  margin-left: 1.5rem;
  margin-bottom: 1.25rem;
}

.prose li {
  margin-bottom: 0.75rem;
}

.prose blockquote {
  background-color: #f9fafb;
  border-left: 4px solid #d1d5db;
  padding: 1rem;
  margin-bottom: 1.25rem;
}

.prose a {
  color: #3b82f6;
  text-decoration: underline;
}

.prose a:hover {
  text-decoration: none;
  color: #1d4ed8;
}

.prose pre {
  background-color: #f1f5f9;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1.25rem;
  overflow-x: auto;
}

.prose code {
  background-color: #f3f4f6;
  padding: 0.2rem 0.4rem;
  border-radius: 4px;
}